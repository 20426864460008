const newSearchParams = () => new URLSearchParams(window.location.search)

export const setSearchParam = (key: string, value: string | undefined) => {
  if (typeof window === 'undefined') return
  const searchParams = newSearchParams()

  if (value) {
    searchParams.set(key, value)
    window.history.replaceState({}, '', `${location.pathname}?${searchParams}`)
    window.dispatchEvent(new Event('searchParamsUpdated'))
  }
}

export const deleteSearchParam = (key: string) => {
  if (typeof window === 'undefined') return
  const searchParams = newSearchParams()
  searchParams.delete(key)
  window.history.replaceState({}, '', `${location.pathname}?${searchParams}`)
  window.dispatchEvent(new Event('searchParamsUpdated'))
}

export const getSearchParam = (key: string) => {
  if (typeof window === 'undefined') return
  const searchParams = newSearchParams()
  return searchParams.get(key)
}
