/** @jsx jsx */
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { ConsentManager, openConsentManager } from '@segment/consent-manager'
import inEU from '@segment/in-eu'

const bannerActionsBlock = ({ acceptAll }) => (
  <div sx={{display: 'flex', justifyContent: 'center'}}>
      <button
      type="button"
      onClick={openConsentManager}
      sx={{
        minWidth: 'fit-content',
        backgroundColor: 'transparent',
        color: 'black',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '2px',
        cursor: 'pointer'
      }}
    >
      Configure
    </button>
    <button
      type="button"
      onClick={acceptAll}
      sx={{
        minWidth: 'fit-content',
        backgroundColor: 'white',
        color: 'black',
        mr: '5px',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '2px',
        cursor: 'pointer'
      }}
    >
      Allow all
    </button>
  </div>
)

const bannerContent = (
  <span>
    We and our third-party service providers and partners may use cookies, tracking pixels, and similar technologies to collect information about you. This information may be used to show you offers and products / services that you may be interested in, track usage of our websites, optimize user experience, and provide targeted advertisements.
  </span>
)

const preferencesDialogContent = (
  <div>
    <p>
      We use data collected by cookies and JavaScript libraries to improve your
      browsing experience, analyze site traffic, deliver personalized
      advertisements, and increase the overall performance of our site.
    </p>
    <p>
      By using our website, you’re agreeing to our{' '}
      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        Website Data Collection Policy
      </a>
      .
    </p>
    <p>
      The table below outlines how we use this data by category. To opt out of a
      category of data collection, select “No” and save your preferences.
    </p>
  </div>
)

const cancelDialogContent = (
  <div>
    Your preferences have not been saved. By continuing to use our website,
    you’re agreeing to our{' '}
    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
      Website Data Collection Policy
    </a>
    .
  </div>
)

const getSegmentWriteKey = () => {
  return process.env.NODE_ENV === 'production' &&
    process.env.CONTEXT === 'production'
    ? process.env.GATSBY_SEGMENT_PRODUCTION_WRITE_KEY
    : process.env.GATSBY_SEGMENT_DEV_WRITE_KEY
}

const onError = error => {
  console.log('ConsentManagerBanner error', error)
}

const shouldRequireConsent = () => {
  if (process.env.GATSBY_SEGMENT_SHOULD_REQUIRE_CONSENT === 'true') {
    return true
  }

  return inEU()
}

/**
 * This file integrates Segment's consent-manager to protect our visitors privacy.
 *
 * See https://github.com/segmentio/consent-manager
 */
const ConsentManagerWrapper = ({ children }) => {
  const writeKey = getSegmentWriteKey()

  return (
    <Fragment>
      {children}

      <div
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 999,
          '& > div > div': {
            pl: '8px',
            pr: '40px',
            '@media (max-width: 767px)': {
              pl: 0,
              pr: '20px',
            },
          },
          '[title="Close"]': {
            right: '8px',
            left: 'auto',
            fontWeight: 'bold',
            '@media (max-width: 767px)': {
              top: '16px'
            },
          }
        }}
      >
        <ConsentManager
          writeKey={writeKey}
          shouldRequireConsent={shouldRequireConsent}
          implyConsentOnInteraction={true}
          bannerContent={bannerContent}
          bannerSubContent="You can manage your preferences here!"
          bannerActionsBlock={bannerActionsBlock}
          bannerHideCloseButton={true}
          bannerAsModal={false}
          bannerTextColor="#1F3438"
          bannerBackgroundColor="#C7C7C7"
          preferencesDialogTitle="Website Data Collection Preferences"
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle="Are you sure you want to cancel?"
          cancelDialogContent={cancelDialogContent}
          onError={onError}
        />
      </div>
    </Fragment>
  )
}

ConsentManagerWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default ConsentManagerWrapper
