exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advocate-tsx": () => import("./../../../src/pages/advocate.tsx" /* webpackChunkName: "component---src-pages-advocate-tsx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-more-js": () => import("./../../../src/pages/blog/more.js" /* webpackChunkName: "component---src-pages-blog-more-js" */),
  "component---src-pages-care-and-cleaning-js": () => import("./../../../src/pages/care-and-cleaning.js" /* webpackChunkName: "component---src-pages-care-and-cleaning-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cookie-preferences-jsx": () => import("./../../../src/pages/cookie-preferences.jsx" /* webpackChunkName: "component---src-pages-cookie-preferences-jsx" */),
  "component---src-pages-discount-[name]-js": () => import("./../../../src/pages/discount/[name].js" /* webpackChunkName: "component---src-pages-discount-[name]-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-confirmation-js": () => import("./../../../src/pages/order/confirmation.js" /* webpackChunkName: "component---src-pages-order-confirmation-js" */),
  "component---src-pages-products-bakeware-sets-js": () => import("./../../../src/pages/products/bakeware-sets.js" /* webpackChunkName: "component---src-pages-products-bakeware-sets-js" */),
  "component---src-pages-products-baking-sheets-js": () => import("./../../../src/pages/products/baking-sheets.js" /* webpackChunkName: "component---src-pages-products-baking-sheets-js" */),
  "component---src-pages-recycle-tsx": () => import("./../../../src/pages/recycle.tsx" /* webpackChunkName: "component---src-pages-recycle-tsx" */),
  "component---src-pages-refer-tsx": () => import("./../../../src/pages/refer.tsx" /* webpackChunkName: "component---src-pages-refer-tsx" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-which-cookware-color-are-you-js": () => import("./../../../src/pages/which-cookware-color-are-you.js" /* webpackChunkName: "component---src-pages-which-cookware-color-are-you-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-product-bundle-tsx": () => import("./../../../src/templates/productBundle.tsx" /* webpackChunkName: "component---src-templates-product-bundle-tsx" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-redirect-jsx": () => import("./../../../src/templates/redirect.jsx" /* webpackChunkName: "component---src-templates-redirect-jsx" */)
}

