module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"public/page-data/":["cache-control: public, max-age=0, must-revalidate"],"static/":["cache-control: public, max-age=31536000, immutable"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-performance/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://908722b28637b6c6df32524ad6eb3215@o4506143334989824.ingest.sentry.io/4506143342985216","environment":"production","enabled":"https://908722b28637b6c6df32524ad6eb3215@o4506143334989824.ingest.sentry.io/4506143342985216","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"wWc6nQChzKi1uOi7BAj5ABEiHPFbflsw","devKey":"wWc6nQChzKi1uOi7BAj5ABEiHPFbflsw","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-performance/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"dfp1t53x5luq","accessToken":"_WIF8mPXMw1OuMu3z1W29LRjDm09tyYpO51lXj6tOyw","host":"cdn.contentful.com","environment":"master"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},"sentryDSN":"https://908722b28637b6c6df32524ad6eb3215@o4506143334989824.ingest.sentry.io/4506143342985216","siteMetadata":{"title":"Caraway","siteUrl":"https://www.carawayhome.com","description":"We’re on a mission to craft well-designed, non-toxic ceramic cookware that thoughtfully raises the standards of what you cook with. Discover Caraway cookware.","social":{"facebook":"carawayhome","instagram":"caraway_home","pinterest":"carawayhome","linkedin":"carawayhome","twitter":"carawayhome"},"contact":{"telephone":"6463890251","contactType":"Customer Service"}},"segmentConfig":{"prodKey":"wWc6nQChzKi1uOi7BAj5ABEiHPFbflsw","devKey":"wWc6nQChzKi1uOi7BAj5ABEiHPFbflsw","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":"./src/gatsby-plugin-theme-ui/index.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
